import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { CONFIG } from 'src/config';
import { DigiformaApiError } from 'src/errors/digiforma-api-error';
import { instrumentation } from 'src/instrumentation/instrumentation';
import { getToken } from 'src/user';

const httpLink = createHttpLink({
  uri: `${CONFIG.serverRoot}/api/v1/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const createErrorLink = ({ onNetworkError }) =>
  onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const { message, path } = error;

        const apiError = new DigiformaApiError(error.message);
        instrumentation.captureException(apiError, {
          extra: {
            operationName: operation.operationName,
          },
        });

        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
      });
    }

    if (networkError) onNetworkError?.(networkError);
  });

export const createApolloClient = ({ onNetworkError }) => {
  const link = ApolloLink.from([createErrorLink({ onNetworkError }), authLink, httpLink]);
  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: true,
    dataIdFromObject: object => `${object.__typename}:${object.id}`,
    defaultOptions: {
      // The useQuery hook uses Apollo Client's watchQuery function
      // see https://www.apollographql.com/docs/react/api/core/ApolloClient/#example-defaultoptions-object
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};
